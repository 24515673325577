import React, { useState } from 'react';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

interface CopyableIdProps {
	copiableIdValue: string;
}

const CopyableId: React.FC<CopyableIdProps> = (props: CopyableIdProps) => {
	const [copied, setCopied] = useState<boolean>(false);
	const { copiableIdValue } = props;
	const handleCopy = () => {
		navigator.clipboard.writeText(copiableIdValue.toString());
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 2000);
	};

	return (
		<Box display="flex" alignItems="center">
			<Typography variant="h6">
				ID: <span>{copiableIdValue}</span>
			</Typography>
			<Tooltip title={copied ? 'Copiado satisfactoriamente' : 'Copiar ID'}>
				<IconButton onClick={handleCopy}>
					{copied ? <CheckIcon style={{ color: 'green' }} /> : <ContentCopyIcon />}
				</IconButton>
			</Tooltip>
		</Box>
	);
};

export default CopyableId;
