import React, { useEffect, useState, ChangeEvent, useCallback } from 'react';
import {
	Grid,
	MenuItem,
	Pagination,
	Select,
	Backdrop,
	CircularProgress,
	SelectChangeEvent,
	Snackbar,
	Table,
	TableBody,
	Alert,
	TableCell,
	Button,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
	InputAdornment,
	IconButton,
	Link,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SearchIcon from '@mui/icons-material/Search';
import { useHistory } from 'react-router-dom';

import { pageSizeOptions } from 'constants/constants';

import { extractErrorMessage } from 'features/quotation/helpers';
import { FilterGrid } from 'features/common/components/FilterGrid/FilterGrid';
import { PageNoResult } from 'features/common/components/PageNoResults';
import { ExportToExcelButton } from 'features/common/components/ExportToExcelButton';
import {
	convertFiltersToRecord,
	createSortHandler,
	ExportExcelOptions,
	exportToExcel,
	getSortableFields,
} from 'features/common/helpers';
import { ISort, FilterModel, ShowAlertState } from 'features/common/types';

import { getSalesOrderList, getSalesOrderListToExport } from '../../services';
import { ISaleOrderListItem } from '../../types';
import { ExpandedRowDetailsSalesOrderList } from '../SalesOrderDetailsList';
import { transformGrossProfit, transformValue } from './helper';
import { saleOrderStyles, balanceStyle } from './styles';
import { headerCells, saleOrderFilters } from './constants';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import routes from '../../../../constants/routes';
export const SalesOrderList = (): JSX.Element => {
	const classes = saleOrderStyles();
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(false);
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [sortFields, setSortFields] = useState<ISort[]>([{ field: 'created', order: 'desc' }]);
	const [currentSize, setCurrentSize] = useState<number>(10);
	const [pageTotal, setPageTotal] = useState<number>(0);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [filterApplied, setFilterApplied] = useState<FilterModel[]>([]);
	const [salesOrderList, setSalesOrderList] = useState<ISaleOrderListItem[] | null>([]);
	const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
	const colSpan = headerCells.length;
	const [searchValue, setSearchValue] = useState<string>('');

	const handleApplyFilters = (filters: FilterModel[]) => setFilterApplied([...filters]);
	const handleExpandRow = (index: number, event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		const updatedRows = new Set(expandedRows);
		if (expandedRows.has(index)) {
			updatedRows.delete(index);
		} else {
			updatedRows.add(index);
		}
		setExpandedRows(updatedRows);
	};
	const handleRowClick = (index: number) => (event: React.MouseEvent<HTMLElement>) => {
		handleExpandRow(index, event);
	};
	const sortableFields = getSortableFields(headerCells);

	const loadSalesOrderList = useCallback(async () => {
		try {
			setLoading(true);
			const envelopedSalesOrderList = (
				await getSalesOrderList(
					currentPage,
					currentSize,
					sortFields,
					convertFiltersToRecord(filterApplied),
					searchValue,
				)
			).data;
			setPageTotal(Math.ceil(envelopedSalesOrderList.total / envelopedSalesOrderList.size));
			setTotalCount(envelopedSalesOrderList.total);
			setSalesOrderList(envelopedSalesOrderList.elements);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al recibir el listado '),
			});
			setLoading(false);
		}
	}, [currentPage, currentSize, sortFields, filterApplied, searchValue]);

	const handleExportToExcel = async () => {
		try {
			setLoading(true);
			const title = 'Listado de ordenes de ventas';
			const envelopedTransactionList = (
				await getSalesOrderListToExport(sortFields, convertFiltersToRecord(filterApplied ?? []))
			).data;
			const listTransactions = envelopedTransactionList.elements;
			const optionsToExport = {
				title: title,
				headers: headerCells,
				widthColumns: [30, 20, 15, 20, 20, 20, 20, 15, 15, 20, 20],
				filename: title,
			} as ExportExcelOptions;
			exportToExcel(listTransactions, optionsToExport, transformValue);
			setLoading(false);
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al exportar el listado'),
			});
			setLoading(false);
		}
	};

	const handleSearch = () => {
		setCurrentPage(1);
		loadSalesOrderList();
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
	};

	const handleCloseAlert = () => {
		setAlert(defaultAlertState);
	};

	const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
		setCurrentSize(+event.target.value);
		setCurrentPage(1);
	};
	const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
		setCurrentPage(page);
	};

	useEffect(() => {
		loadSalesOrderList();
	}, [filterApplied, sortFields, currentPage, currentSize, loadSalesOrderList]);

	const handleGoNewSalesOrder = () => {
		history.push('/salesorder/new');
	};

	// @ts-ignore
	const capitalizeWords = (str) => {
		// @ts-ignore
		return str ? str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) : '';
	};

	const handleOpenViewSyncCustomersStatus = () => {
		const newRoute = routes.salesOrder.customersSyncStatus;
		const baseUrl = window.location.origin;
		window.open(`${baseUrl}${newRoute}`, '_blank');
	};

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid container sx={{ justifyContent: 'center' }} gap={1}>
				<Grid container spacing={2} className={classes.container}>
					<Grid item xs={3}>
						<Button variant="contained" onClick={handleGoNewSalesOrder}>
							{' '}
							+ Nueva Orden{' '}
						</Button>
					</Grid>
					<Grid item xs={9} className={classes.containerButtons} gap={1}>
						<TextField
							label="Buscar"
							variant="outlined"
							size="small"
							value={searchValue}
							onChange={handleChange}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={handleSearch}>
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<FilterGrid filters={saleOrderFilters} handleAppliedFilters={handleApplyFilters} />
						<ExportToExcelButton disabled={salesOrderList?.length == 0} handleExportToExcel={handleExportToExcel} />
						<Button
							variant="contained"
							size="large"
							startIcon={<SyncAltIcon />}
							sx={{ height: 40, width: 30 }}
							title={'Ver estado de proceso de sincronización de clientes con Netsuite'}
							onClick={handleOpenViewSyncCustomersStatus}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12} sx={{ m: 5, justifyContent: 'flex-start', ml: 19 }}>
					mostrando {salesOrderList?.length} resultados de {totalCount}
				</Grid>
				<TableContainer component="main" sx={{ maxWidth: 'xl' }}>
					<div>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									<TableCell align="center"></TableCell>
									{headerCells.map((cell) => {
										return cell.sortable ? (
											<TableCell
												key={cell.field}
												align="center"
												sortDirection={
													sortFields.find((x) => x.field === cell.field)
														? sortFields.find((x) => x.field === cell.field)?.order
														: false
												}
											>
												<TableSortLabel
													active={cell.sortable && sortFields.find((x) => x.field === cell.field) !== undefined}
													className={classes.tableSortLabel}
													direction={
														sortFields.find((x) => x.field === cell.field)
															? sortFields.find((x) => x.field === cell.field)?.order
															: 'asc'
													}
													onClick={createSortHandler(cell.field, sortFields, sortableFields, setSortFields)}
												>
													{cell.headerName}
												</TableSortLabel>
											</TableCell>
										) : (
											<TableCell className={classes.tableHeader} key={cell.field} align="center">
												{cell.headerName}
											</TableCell>
										);
									})}
									<TableCell align="center"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{salesOrderList?.length === 0 ? (
									<TableRow>
										<TableCell colSpan={colSpan}>
											<PageNoResult />
										</TableCell>
									</TableRow>
								) : (
									salesOrderList?.map((salesOrderItem, rowIndex) => (
										<React.Fragment key={salesOrderItem.id}>
											<TableRow
												key={salesOrderItem.id}
												className={expandedRows.has(rowIndex) ? classes.expandedRow : ''}
												onClick={handleRowClick(rowIndex)}
											>
												<TableCell align="center">
													{' '}
													<RequestQuoteIcon className={classes.icon} />{' '}
												</TableCell>
												<TableCell align="center" style={{ padding: '10px' }} component="p">
													{transformValue('created', salesOrderItem.created, salesOrderItem)}{' '}
												</TableCell>
												<TableCell align="center" component="p">
													{salesOrderItem.createdBy}
												</TableCell>
												<TableCell align="center" component="p">
													<Link
														href={`https://mevuelo.bitrix24.es/crm/deal/details/${salesOrderItem.dealId}/`}
														target="_blank"
														rel="noopener noreferrer"
													>
														{salesOrderItem.dealId}
													</Link>
												</TableCell>
												<TableCell align="center" component="p">
													{capitalizeWords(salesOrderItem.mainPassengerCompleteName)}
												</TableCell>
												<TableCell align="center" component="p">
													{salesOrderItem.quotationId && salesOrderItem.quotationId !== '-' ? (
														<Link
															href={`https://power.mevuelo.com/quotations/${salesOrderItem.quotationId}/`}
															target="_blank"
															rel="noopener noreferrer"
														>
															{transformValue('quotationId', salesOrderItem.quotationId, salesOrderItem)}
														</Link>
													) : (
														<span>{transformValue('quotationId', salesOrderItem.quotationId, salesOrderItem)}</span>
													)}
												</TableCell>
												<TableCell align="center" component="p">
													{salesOrderItem.currency}
												</TableCell>
												<TableCell align="center" component="p">
													{transformValue('salesAmount', salesOrderItem.saleAmount, salesOrderItem)}
												</TableCell>
												<TableCell align="center" component="p">
													{transformValue('totalCost', salesOrderItem.totalCost, salesOrderItem)}
												</TableCell>
												<TableCell align="center" component="p">
													{transformGrossProfit(salesOrderItem.grossProfit)}
												</TableCell>
												<TableCell align="center" component="p">
													{transformValue('chargedAmount', salesOrderItem.chargedAmount, salesOrderItem)}
												</TableCell>
												<TableCell
													align="center"
													component="p"
													style={balanceStyle(salesOrderItem.pendingPayAmount, salesOrderItem.travelDepartureDate)}
												>
													{transformValue('pendingPayAmount', salesOrderItem.pendingPayAmount, salesOrderItem)}{' '}
												</TableCell>
												<TableCell align="center">
													<button
														style={{ border: 0, background: 'transparent' }}
														onClick={(e) => handleExpandRow(rowIndex, e)}
													>
														{expandedRows.has(rowIndex) ? (
															<ExpandLessIcon className={classes.icon} />
														) : (
															<ExpandMoreIcon className={classes.icon} />
														)}
													</button>
												</TableCell>
											</TableRow>
											{expandedRows.has(rowIndex) && <ExpandedRowDetailsSalesOrderList rowData={salesOrderItem} />}
										</React.Fragment>
									))
								)}
							</TableBody>
						</Table>
					</div>
				</TableContainer>
				<Grid item xs={12}>
					<Grid container justifyContent="center" flexDirection="row" padding={2}>
						<Grid item xs={12} md={1} textAlign="center">
							<Select value={currentSize} onChange={handleRowsPerPageChange}>
								{pageSizeOptions.map((value) => (
									<MenuItem key={value} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} md={6} marginTop={1}>
							<Pagination count={pageTotal} page={currentPage} onChange={handlePageChange} color="primary" />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Snackbar open={alert.show} autoHideDuration={2000} onClose={handleCloseAlert}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
};
