import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';

import routes from 'constants/routes';
import { CookieService } from 'features/auth/services/CookieService';

import QuotationsNew from 'pages/Quotations/new';
import QuotationsList from 'pages/Quotations/list';
import QuotationEdit from 'pages/Quotations/edit';

import Login from 'pages/Login';
import ClientNotLogingAllow from 'pages/ClientNotLoging/indx';

import AgencyNew from 'pages/Agency/new';
import AgencyUpdate from 'pages/Agency/update';

import { QuotaDataReport } from 'pages/QuotaData/index';
import { SalesOrderListPage } from 'pages/SalesOrder/salesOrderList';
import { CashPaymentPage } from 'pages/Payment/CashPayment';
import UserList from 'pages/User/list';
import UserNew from 'pages/User/new';
import ClientPreview from './pages/Quotations/clientPreview';
import makeStyles from '@mui/styles/makeStyles';
import packageJson from '../package.json';
import ClientPaymentOrder from 'pages/Payment/ClientPaymentOrder';
import TransactionList from 'pages/Transactions/list';
import PackageStats from 'pages/Stats/Packages';
import TransactionDetails from 'pages/Transactions/details';
import AuthorizationDetails from 'pages/Authorization/details';
import AuthorizationList from 'pages/Authorization/list';
import CrossList from 'pages/Cross/list';
import GatewayTransactionList from 'pages/Transactions/gatewayList';
import { BancardCheckConfirmation } from './pages/Payment/CheckBancardPaymentOrder';
import { ThankYouTransaction } from './pages/Payment/ThankYouTransaction';
import Rates from './pages/Stats/Rates';
import ReservationSearch from './pages/Reservation/search';
import HotelSearch from './pages/Hotel/search';
import HotelFareCard from './pages/Hotel/fareCard';
import { WireTransferCheck } from './pages/Payment/WireTransferCheck';
import WireTransferTransactions from './pages/Transactions/wireTransferTransactions';
import QuotationDefaultDescriptionConfig from './pages/Quotations/defaultDescriptionConfig';
import { DefaultNotIncludes } from './pages/Quotations/defaultNotIncludes';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { DataStudioSellerReport } from './pages/DataStudio/SellerReport';
import AutomaticRouteStats from './pages/Stats/AutomaticRoutes';
import { PackageExplorer } from './pages/PackageExplorer/mainPage';
import { PackageExplorerDestinationDetailPage } from './pages/PackageExplorer/destinationDetail';
import { FightExplorer } from './pages/FlightExplorer/mainPage';
import { AtcCheckConfirmation } from './features/payment/components/AtcCheckConfirmation';
import { CancelTransaction } from './pages/Payment/CancelTransaction';
import { FlightExplorerDestinationDetailPage } from './pages/FlightExplorer/destinationDetail';
import { CashTransactionPage } from './pages/Transactions/CashTransactionPage';
import { OrderPage } from './pages/Payment/OrderPage';
import { OrderPageViajeNaranja } from 'pages/Payment/OrderPageViajeNaranja';
import { SalesOrderCreatePage } from './pages/SalesOrder/saleOrderCreate';
import QuotationsNewOnlyFlights from './pages/Quotations/newOnlyFlights';
import QuotationsEditOnlyFlight from './pages/Quotations/editOnlyFlights';
import ViajeNaranjaBancardTransactionList from './pages/Transactions/bancardListOrangeAgency';
import VNItauPointTransactionList from 'pages/Transactions/VNItauPointTransactionList';
import SaleReportPage from './pages/Quotations/SaleReport';
import { mevuelo } from './assets/themes';
// import { SalesOrderView } from './pages/SalesOrder/saleOrderView';
import { ActivityLogListPage } from './pages/ActivityLog/List';
import { WompiCheckConfirmation } from 'pages/Payment/CheckWompiPaymentOrder';
import { ProvidersList } from 'pages/Providers/list';
import { CustomersList } from 'pages/Customers/list';
import { SalesOrderScreen } from 'pages/SalesOrder/SalesOrderScreen';
import { FlightIssueRuleUpsertPage } from './pages/FlightIssueRule/FlightIssueRuleUpsertPage';
import { FlightIssueRuleListPage } from './pages/FlightIssueRule/FlightIssueRuleListPage';
import { CustomersSyncProcessStatusPage } from './pages/SalesOrder/customersSyncProcessStatusPage';

export const APP_VERSION = packageJson.version;

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles(() => ({
	version: {
		color: '#31194B',
		fontSize: '12px',
		fontWeight: 'bold',
		position: 'fixed',
		bottom: '0.5rem',
		right: '0.5rem',
		padding: '0 0.5rem',
		borderRadius: '6px',
		cursor: 'pointer',
		transition: 'all 0.3s',
		zIndex: '200',
		backgroundColor: 'white',
	},
}));

function App(): JSX.Element {
	const token = CookieService.getToken();
	const classes = useStyles();
	const { auth } = useSelector((state: RootState) => state);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={mevuelo}>
				<Router forceRefresh={true}>
					{token ? (
						<Switch>
							<Route exact path={routes.quotations.new}>
								<QuotationsNew />
							</Route>
							<Route exact path={routes.quotations.newOnlyFlights}>
								<QuotationsNewOnlyFlights />
							</Route>
							<Route exact path={routes.quotations.edit}>
								<QuotationEdit />
							</Route>
							<Route exact path={routes.quotations.editOnlyFlights}>
								<QuotationsEditOnlyFlight />
							</Route>
							<Route exact path={routes.quotations.list}>
								<QuotationsList />
							</Route>
							<Route exact path={routes.quotations.saleReport}>
								<SaleReportPage />
							</Route>
							<Route exact path={routes.salesOrder.new}>
								<SalesOrderCreatePage />
							</Route>
							<Route exact path={routes.salesOrder.list}>
								<SalesOrderListPage />
							</Route>
							{/* <Route exact path={routes.salesOrder.edit}>
								<SalesOrderView />
							</Route> */}
							<Route exact path={routes.salesOrder.edit}>
								<SalesOrderScreen />
							</Route>
							<Route exact path={routes.hotels.search}>
								<HotelSearch />
							</Route>
							<Route path={routes.providers.list}>
								<ProvidersList />
							</Route>
							<Route path={routes.customers.list}>
								<CustomersList />
							</Route>
							<Route path={routes.agency.new}>
								<AgencyNew />
							</Route>
							<Route path={routes.agency.update}>
								<AgencyUpdate />
							</Route>
							<Route path={routes.payment.order}>
								<OrderPage />
							</Route>
							<Route path={routes.payment.orderNaranja}>
								<OrderPageViajeNaranja />
							</Route>
							<Route path={routes.payment.wireTransferCheck}>
								<WireTransferCheck />
							</Route>
							<Route path={routes.payment.orderCash}>
								<CashPaymentPage />
							</Route>
							<Route path={routes.transactions.cashTransactions}>
								<CashTransactionPage />
							</Route>
							<Route exact path={routes.packageExplorer.list}>
								<PackageExplorer />
							</Route>
							<Route path={routes.packageExplorer.destinationDetail}>
								<PackageExplorerDestinationDetailPage />
							</Route>
							<Route exact path={routes.flightExplorer.list}>
								<FightExplorer />
							</Route>
							<Route exact path={routes.flightExplorer.destinationDetail}>
								<FlightExplorerDestinationDetailPage />
							</Route>
							<Route path={routes.user.list}>
								<UserList />
							</Route>
							<Route path={routes.user.new}>
								<UserNew />
							</Route>
							<Route exact path={routes.transactions.list}>
								<TransactionList />
							</Route>
							<Route exact path={routes.transactions.wireTransferTransactions}>
								<WireTransferTransactions />
							</Route>
							<Route exact path={routes.transactions.gatewayTransactionList}>
								<GatewayTransactionList />
							</Route>
							<Route exact path={routes.transactions.viajeNaranjaBancardTransactionList}>
								<ViajeNaranjaBancardTransactionList />
							</Route>
							<Route exact path={routes.transactions.viajeNaranjaItauPointTransactionList}>
								<VNItauPointTransactionList />
							</Route>
							<Route path={routes.stats.package}>
								<PackageStats />
							</Route>
							<Route path={routes.stats.automaticRoute}>
								<AutomaticRouteStats />
							</Route>
							<Route path={routes.transactions.details}>
								<TransactionDetails />
							</Route>
							<Route exact path={routes.authorizations.list}>
								<AuthorizationList />
							</Route>
							<Route exact path={routes.crosses.list}>
								<CrossList />
							</Route>
							<Route path={routes.authorizations.details}>
								<AuthorizationDetails />
							</Route>
							<Route path={routes.stats.ratesList}>
								<Rates />
							</Route>
							<Route exact path={routes.hotels.fareCard}>
								<HotelFareCard />
							</Route>
							<Route path={routes.hotels.searchReservations}>
								<ReservationSearch />
							</Route>
							<Route exact path={routes.quotations.description}>
								<QuotationDefaultDescriptionConfig />
							</Route>
							<Route exact path={routes.quotations.defaultNotIncludes}>
								<DefaultNotIncludes />
							</Route>
							<Route exact path={routes.dataStudio}>
								<DataStudioSellerReport />
							</Route>
							<Route exact path={routes.quotaData}>
								<QuotaDataReport />
							</Route>
							<Route exact path={routes.activityLogList}>
								<ActivityLogListPage />
							</Route>
							<Route exact path={routes.flightIssueRule.list}>
								<FlightIssueRuleListPage />
							</Route>
							<Route exact path={routes.flightIssueRule.upsert}>
								<FlightIssueRuleUpsertPage />
							</Route>
							<Route exact path={routes.salesOrder.customersSyncStatus}>
								<CustomersSyncProcessStatusPage />
							</Route>
							{/*<Route exact path={'/test'}>*/}
							{/*	<FlightItineraryCard />*/}
							{/*</Route>*/}
							{/* DEFAULT */}
							<Route path={routes.home}>
								{!auth.user?.dataStudioUrl && <Redirect to={routes.quotations.list} />}
								{auth.user?.dataStudioUrl && <Redirect to={routes.dataStudio} />}
							</Route>
						</Switch>
					) : (
						<Switch>
							<Route path={routes.auth.login}>
								{window.location.hostname === 'tuviaje.mevuelo.com' ? (
									<Redirect to={routes.clientNotLogin} />
								) : (
									<Login />
								)}
							</Route>
							<Route path={routes.clientNotLogin}>
								<ClientNotLogingAllow />
							</Route>

							<Route exact path={routes.quotations.previewClient}>
								<ClientPreview />
							</Route>

							<Route exact path={routes.payment.clientTransaction}>
								<ClientPaymentOrder />
							</Route>
							<Route exact path={routes.payment.bancardCheckConfirmation}>
								<BancardCheckConfirmation />
							</Route>
							<Route exact path={routes.payment.wompiCheckConfirmation}>
								<WompiCheckConfirmation />
							</Route>
							<Route path={routes.payment.atcCheckConfirmation}>
								<AtcCheckConfirmation />
							</Route>
							<Route exact path={routes.payment.thankYouTransaction}>
								<ThankYouTransaction />
							</Route>
							<Route exact path={routes.payment.cancelTransaction}>
								<CancelTransaction />
							</Route>
							<Route path={routes.home}>
								<Redirect to={routes.auth.login} />
							</Route>
						</Switch>
					)}
					<div className={classes.version}>{APP_VERSION}</div>
				</Router>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

export default App;
